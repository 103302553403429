<template>
    <div v-if="showLocationPermissionPopup"
        class="position-fixed w-100 h-100 d-flex align-items-center justify-content-center flex-column text-center">
        <i class="fa-solid fa-location-crosshairs text-danger mb-4" style="font-size: 150px;"></i>
        <h1 class="text-danger text-bold">
            <strong>
                Uygulama için konum izni gerekiyor
            </strong>
        </h1>
        <h5 class="text-muted ">
            Açılır pencere görünmezse, izin ayarlarınızı kontrol edin.
        </h5>
        <button @click="askLocationPermission()" class="mt-3 btn btn-success btn-lg">Izin ver</button>
    </div>

    <div v-else>
        <div ref="map" id="map"></div>
        <ReportDetail v-if="report" :report="report" @close="closeReport" />

        <div v-show="!report" class="card element-in-view">
            <div class="card-header bg-dark text-white">
                Yakindaki destek yerleri <span v-if="reports">
                    ({{ reports.length }})</span>
            </div>
            <ul class="list-group list-group-flush overflow-auto">
                <li class="list-group-item" v-for="item in reports" :key="item.id">

                    <h5 class="card-title">{{ item.title }}</h5>
                    <h6 class="card-subtitle mb-2 text-muted">{{
                        Number.parseFloat(item.distance).toFixed(2)
                    }}km</h6>
                    <p class="card-text" v-if="item.description">{{ item.description }}</p>

                    <button type="button" class="btn btn-primary"
                        @click="setReport(item.latitude, item.longitude)">Detay</button>
                    <button type="button" class="ms-1 btn btn-warning"
                        @click="setView(item.latitude, item.longitude)">Haritada göster</button>
                    <a target="_blank" :href="`https://maps.google.com/?q=${item.latitude},${item.longitude}`"
                        class="ms-1 btn btn-success">Google Maps</a>
                </li>
            </ul>
        </div>
    </div>
</template>


<script setup>
import ReportDetail from "@/components/ReportDetail.vue";
import ReportService from "@/services/ReportService";
import { onMounted } from "@vue/runtime-core";
import leaflet from "leaflet";
import { ref } from "vue";


// let map;

let _map;


const map = () => {
    if (!_map) {
        _map = leaflet.map("map")
    }

    return _map;

}


const position = ref()
const positionMarker = ref()
const reports = ref()
const report = ref()
const showLocationPermissionPopup = ref(false)

// const renderPosition = () => {

// }

const askLocationPermission = () => {
    navigator.geolocation.getCurrentPosition(() => {
        showLocationPermissionPopup.value = false
        registerPositionWatcher();
    }, () => {
        showLocationPermissionPopup.value = true
        alert("Konum izni ayarlarinizi kontrol edin")
    }, {
        maximumAge: 60000,
        timeout: 5000,
        enableHighAccuracy: true
    })
}

const setView = (lat, lng) => {
    map().setView([lat, lng], 18)
}
const registerPositionWatcher = () => {
    if (!("geolocation" in navigator)) {
        alert("No geolocation")
        return;
    }

    navigator.geolocation.watchPosition(async (pos) => {
        position.value = pos

        if (!reports.value) {
            await fetchReports()
        }

        positionMarker.value = leaflet.marker([position.value.coords.latitude, position.value.coords.longitude], {
            radius: 5,
            icon: leaflet.icon({
                iconUrl: "https://icon-library.com/images/2018/2112015_marker-circle-map-marker-circle-png-png-download.png",
                iconSize: [30, 30]
            })
        }).addTo(map())

        leaflet.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }).addTo(map());


    }, () => { }, {
        maximumAge: 60000,
        timeout: 5000,
        enableHighAccuracy: true
    })
}


const refactorBounds = () => {
    const bounds = new leaflet.LatLngBounds(reports.value.map(report => [report.latitude, report.longitude]))
    map().fitBounds(bounds)

    // const bounds = new leaflet.LatLngBounds(reports.value.map(report => [report.latitude, report.longitude]))
    // map.fitBounds(bounds)
}


const setReport = (lat, lng) => {
    report.value = reports.value.find(match => match.latitude == lat && match.longitude == lng)

    document.body.classList.add("item-in-view")

    setView(lat, lng)
}

const closeReport = () => {
    document.body.classList.remove("item-in-view")

    report.value = null
    refactorBounds()
}


const fetchReports = async () => {
    const { data } = await ReportService.fetch(position.value.coords.latitude, position.value.coords.longitude)
    reports.value = data

    reports.value.forEach(report => {
        const marker = leaflet.marker([report.latitude, report.longitude], {
            color: 'red',
            radius: 5,
            icon: leaflet.icon({
                iconUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Google_Maps_pin.svg/274px-Google_Maps_pin.svg.png",
                iconSize: [20, 35]
            })
        }).addTo(map())
        marker.on("click", () => setReport(report.latitude, report.longitude))
    })

    refactorBounds()
}


onMounted(() => {
    askLocationPermission()
})

</script>

<style lang="scss">
.element-in-view {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50vh;
}

#map {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 50vh;
    width: 100vw;
}
</style>