import Api from "@/services/Api";

export default {
    fetch(lat, lng) {
        return Api().get('/reports', {
            params: {
                lat,
                lng
            }
        })
    }
}